



















































import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiCard from '@/components/ui/UiCard.vue';

@Component({
  components: { UiCard },
})
export default class Announcements extends BaseVue {
  showAnnouncements = false;
  expandedAnnouncement = -1;
  page = 1;
  pageSize = 4;

  readonly announcements = [
    {
      title: 'Important Update for Coinbase Pro Users',
      content: `
              We’d like to ensure you are prepared for the planned sunset of the Coinbase Pro API service - effective tomorrow.
              <br>
              <br>
              As <a href="https://www.coinbase.com/blog/hello-advanced-trade-goodbye-coinbase-pro" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">announced in June 2022</a>, Coinbase Pro will be discontinued, and user activity will be consolidated into other Coinbase products, like Coinbase Advanced.
              <br>
              <br>
              <b>WHAT THIS MEANS</b>
              <br>
              ● If you currently use a legacy Coinbase Pro connection with Bitwave, you will no longer receive new data from this source starting tomorrow.
              <br>
              ● All previous Pro users should confirm that they have been migrated to a new Coinbase service.
              <br>
              <br>
              <b>WHAT ACTIONS ARE NEEDED</b>
              <br>
              ● If you’ve moved to Coinbase Prime, please contact your Bitwave Solutions representative to set up your new API key.
              <br>
              ● This step will ensure uninterrupted data synchronization within Bitwave.
              <br>
              <br>
              <b>OUR COMMITMENT</b>
              <br>
              Bitwave works closely with data source providers – including Coinbase – to help you prepare for important changes. We're dedicated to delivering the highest level of quality for our customers and are able to provide you with this information thanks to our strong industry relationships.
              <br>
              <br>
              With Bitwave, you're always ahead of the curve and ready for what's next. &#127754;
              <br>
              <br>
              Questions? Reach us at <a href="mailto:solutions@bitwave.io" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">solutions@bitwave.io</a>.
              <br>
              <br>
              -The Bitwave Team
              `,
      date: 'Sep 10',
    },
    {
      title: 'August Product Release Notes',
      content: `
            We're excited to share several key updates and enhancements designed to improve your experience and streamline your workflow and ensure more accurate and efficient operations.
              <br>
              <br>
              ● <b>Enhanced UI Responsiveness:</b> Improved UI responsiveness for faster loading of tools and balances, specifically for larger organizations.
              <br>
              ● <b>New Accounting Connections Page:</b> Enjoy a sleeker and more consistent look with the newly launched Accounting Connections page, aligning with other recent updates for a cohesive user experience.
              <br>
              ● <b>SoDA Report:</b> New wallet roles have been added, allowing you to search by role name, add or delete roles, and share them with users more efficiently.
              <br>
              ● <b>Foundry API Key Validation:</b> To ensure accuracy, we've implemented validation for API keys, so only correct keys can be entered when adding connections.
              <br>
              ● <b>Sage Top Level ERP Creation Enhancement:</b> The entity-level launch now enables posting individual lines to different entities, enhancing flexibility in ERP management.
              <br>
              ● <b>Metadata Fields Import CSV:</b> Sage customers can now categorize metadata through imports, making it easier to organize and manage your data.
              <br>
              ● <b>Org Navigation Enhancements:</b> You can now open multiple tabs for different organizations, with an alert system in place to warn users logging in with outdated or expired requests.
              <br>
              ● <b>Partner Wallet Manager Integration:</b> Partners can now seamlessly sync blockchain wallets created by clients directly into the organization.
              <br>
              ● <b>Binance Smart Chain Upgrade:</b> We’ve implemented a more efficient syncing method, improving performance and data integration for a smoother user experience.
              <br>
              `,
      date: 'Sep 2',
    },
    {
      title: 'Missed EDAS? Earn Free CPE & Catch Up on the Action',
      content: `
            Couldn't join us in person for last month’s <a href="https://www.bitwave.io/blog/bitwave-current-introducing-bitwave-2-0-data-fusion" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">Enterprise Digital Asset Summit (EDAS)</a>? We’ve got you covered.
              <br>
              <br>
              EDAS session recordings are now available for <a href="https://university.bitwave.io/bundles/enterprise-digital-asset-summit-2024" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">free, on-demand viewing</a> with up to 6 hours of NASBA-licensed CPE available: 
              <br>
              <br>
              ● The State of Enterprise Adoption for Digital Assets.
              <br>
              ● B2B Enterprise Payments: A Roundtable with Coinbase & Google.
              <br>
              ● Crypto Tax Update with Deloitte: New Treasury Regulations.
              <br>
              ● And 7 more must-watch sessions packed with insights.
              <br>
              <br>
              Sharpen your finance skills, stay ahead of industry trends, and earn those valuable CPE credits today.
              <br>
              <br>
              Sign up for a free <a href="https://university.bitwave.io/bundles/enterprise-digital-asset-summit-2024" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">Bitwave University</a> account and dive into our course catalog. It’s that simple!
              `,
      date: 'Aug 21',
    },
    {
      title: 'Introducing Bitwave 2.0: Speed, Efficiency, & Collaboration',
      content: `
            We're excited to announce the launch of Bitwave 2.0 – a complete platform upgrade designed to revolutionize the way you manage digital assets. Re-built from the ground up, Bitwave 2.0 is more than just an upgrade; it's a leap forward in the digital asset finance ecosystem.
              <br>
              <br>
              ● <b>Experience unparalleled speed and scale.</b> Our reimagined data layer can effortlessly handle billions of transactions, delivering lightning-fast reporting and insights.
              <br>
              ● <b>Collaborate seamlessly with your team.</b> Real-time updates and in-line commenting empower your team to work together efficiently, no matter where they are.
              <br>
              ● <b>Gain deeper insights.</b> With enhanced filtering options and bulk syncing, you'll spend less time on data management and more time making informed decisions.
              <br>
              ● <b>Elevate your workflow.</b> Our new user interface is intuitive and packed with features like transaction commenting, change tracking, and file attachments to streamline your processes.
              <br>
              <br>
              Ready to see the future of digital asset finance? <a href="https://www.bitwave.io/blog/introducing-bitwave-2-0-speed-efficiency-collaboration" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">Watch the demo now</a> and get ready to experience Bitwave, evolved.
              `,
      date: 'Aug 08',
    },
    {
      title: 'The EDAS 2024 Attendee Guide is Here! (Download PDF)',
      content: `
            Are you joining the on-chain finance and accounting event of the year? (July 24, Nashville)
            <br>
            <br>
            Bitwave’s third annual Enterprise Digital Asset Summit (<a href="http://edas.live" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">edas.live</a>) 
            is coming to Nashville next week - and we want to see you there! Check out the 2024 Attendee Guide for agenda info, speakers, FAQ’s, and more. 
            <br>
            <br>
            Don’t have a ticket yet? Contact a Bitwave team member and ask how you can attend powerful panel discussions and insightful headlining sessions – led by world-class speakers 
            from Coinbase, Deloitte, EisnerAmper, Google, Magic Eden, Hedera, KPMG, PwC, and more. 
            <br>
            <br>
            EDAS Attendee Guide (PDF): <a href="https://drive.google.com/file/d/1yTWzH_372RsdjfczMfkcBVxii6qlIxEX/view?usp=sharing" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">HERE</a>
            <br>
            <br>
            See you soon in Nashville! 
        `,
      date: 'July 05',
    },
    {
      title: 'Important: USDC Symbol Update in Bitwave (Polygon Network)',
      content: `
      To streamline user workflows, we're updating the way USDC tokens are displayed on Bitwave.
        <br>
        <br>
        What’s Happening? The symbols for Native and Bridged USDC on Polygon are updating:
        <br>
        <br>
            <ul>
                <li>
                    &emsp;1. Native USDC(on Polygon) will use the new symbol: POLY-USDC
                </li>
                <li>
                    &emsp;2. Bridged USDC (on Polygon) will use the new symbol: POLY-USDC.e
                </li>
            </ul>
        <br>
        No action is required for the majority of users. If you are importing these tokens, please ensure you use the correct token identifier.
        For more information, <a href="https://www.bitwave.io/blog/important-usdc-symbol-update-in-bitwave-polygon-network" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">please see our full statement</a>.
        `,
      date: 'June 07',
    },
    {
      title: 'Consensus 2024: Meet Bitwave in Texas!',
      content: `
      We're excited to announce Bitwave's participation in Consensus 2024!
        <br>
        <br>
        Join us for a whirlwind week of education, connection, and innovation. Find our team at <b>booth #1318</b>, where you can enter to win a free ticket to #EDAS2024. And, don't miss Co-Founders Pat White and Amy Kalnoki speaking on some must-see panel sessions:
        <br>
        <br>
        ● <b>Crypto Tax Toolkit:</b> Surviving an IRS Exam (feat. Deloitte, Fuel3, and Propeller Industries)
        <br>
        ● <b>Bridging the Gap:</b> Strategies to Prepare Institutions for Crypto Adoption Success (feat. Franklin, Cold.xyz, Mass, & Propeller Industries)
        <br>
        <br>
        Head over to <a href="https://www.bitwave.io/blog/consensus-2024-meet-bitwave-in-texas" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">our blog</a> for all the details.
        `,
      date: 'May 24',
    },
    {
      title: 'Inventory View Cost Basis Rollforward now has FMV and Unrealized Gains/Losses',
      content: `
      We've added Fair Market Value and Unrealized gain information into the cost basis rollforward report as heavily requested by many of our partners and customers.
        <br>
        <br>
        To see this please navigate to Left Menu -> Inventory views -> select your inventory view -> reports and head to Cost Basis Rollforward Report.
        <br>
        <br>
        This completes the full valuation reporting picture as you are able to see starting costbasis/carrying value, changes up or down, impairment expense, ending cost basis with the addition of unrealized gains and fair market value.`,
      date: 'Apr 17',
    },
    {
      title: 'ANNOUNCING EDAS 2024 (Nashville, July 24) – Unlocking the Future of On-Chain Finance',
      content: `
      Bitwave’s third annual Enterprise Digital Asset Summit (EDAS) welcomes finance leaders for an exciting, one-day summit to shape the next phase of digital asset innovation and adoption. Register now to join us for powerful panel discussions, insightful headlining sessions, and deep networking opportunities.
        <br>
        <br>
        As a valuable Bitwave customer, you can unlock the LOWEST price possible!
        <br>
        <br>
        Use promo code: <b>BITWAVE100</b>. More info: <a href="https://www.edas.live/" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">edas.live</a>.`,
      date: 'Apr 3',
    },
    {
      title: 'Bitwave Current February 2024 Edition is now live!',
      content: `
        Check out our February edition now for product announcements, a deep-dive demo, and tons more to elevate your finance and accounting workflows.
        <br>
        <br>
        Please see our <a href="https://www.bitwave.io/blog/the-bitwave-current-feb-2024" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">full blog post</a> to stay up to date with the most current news.`,
      date: 'Feb 29',
    },
    {
      title: 'Attn. Coinbase Prime Users: Important Notice Impacting ETH / ETH2 Balances',
      content: `In the near future, Coinbase Prime will merge ETH2 with staked Ethereum (ETH).
        <br>
        <br>
        This retroactive update will impact gain-loss reports and inventory views for those tracking ETH and ETH2 as separate assets in Bitwave.
        <br>
        <br>
        Once these changes are live, Bitwave customers may need to review their balances for staked ETH and ETH2 transactions.
        <br>
        <br>
        We're here to help you navigate this change, if you have any questions.
        <br>
        <br>
        Please see our <a href="https://www.bitwave.io/blog/important-update-eth-staking-for-coinbase-prime-users" target="_blank" class="tw-ml-auto tw-underline tw-text-primary-400">full statement</a> for details on how this may impact you.`,
      date: 'Feb 22',
    },
    {
      title: 'Bitwave 2024 Q1 Product Release Notes',
      content:
        'Bitwave’s 2024 Q1 product release focused on ensuring FASB readiness, enterprise grade staking, and making user’s workflows faster and more efficient. Read the full product release notes on our blog.',
      link: 'https://www.bitwave.io/blog',
      date: 'Jan 31',
    },
    {
      title:
        'FEATURE UPDATE: Gain Loss Scenario Runner to be Retired Feb 15. Upgrade to Gain Loss Via Inventory Views!',
      content:
        'Its officially 12+ months since we launched our new gain loss capability! The current gain loss scenario runner will be retired and unavailable Effective April 30th 2024 in favor of the new enhanced "inventory view" gain loss capability. Please reach out to our support team via In App Chat for assistance setting up your gain loss information using the inventory view feature.',
      date: 'Jan 31',
    },
    {
      title: 'New SERVICE: Get Help without EVER leaving the Bitwave App!',
      content: `We've released our new in app chat and ticketing system so that our customers never have to leave the app to get help. Simply click on the chat button in the bottom right our bitwave experts are ready to assist you.`,
      date: 'Jan 15',
    },
    {
      title: `New FEATURE: JE's on the Inventory View Actions Report`,
      content: `Actions report now contains, Contact, Category, Department, Class and Location.  You can now see your categorizations on the Actions Report (in addition to the Journal Entry Reports).`,
      date: 'Jan 15',
    },
    {
      title: 'New FEATURE: New Invoice Match UI',
      content:
        'We launched a new invoice match UI which can be found under AR/AP -> Invoice Match. This UI allows easy application of transaction data to Customer Invoices and Vendor Bills. Reach out to us via the chat button below for any questions...',
      date: 'Jan 15',
    },
    {
      title: 'New FEATURE: Import and load any file type into Bitwave',
      content:
        'With Bitwave Transforms we can automatically import data from any data source that has a repeatable file format. Reach out to us via the chat button below for any questions...',
      date: 'Jan 15',
    },
  ];

  mounted() {
    setTimeout(() => (this.showAnnouncements = true), 500);
  }

  get announcementsOnCurrentPage() {
    return this.announcements.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
  }

  getIcon(index: number) {
    return index === this.expandedAnnouncement ? 'fa-angle-up' : 'fa-angle-down';
  }

  showContent(index: number) {
    if (index === this.expandedAnnouncement) this.expandedAnnouncement = -1;
    else this.expandedAnnouncement = index;
  }
}
